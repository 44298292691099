<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta && $route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta || !$route.meta.keepAlive" />

    <!-- 登录 -->
    <pop-login v-if="showLogin" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import PopLogin from "@/components/popLogin";

import {  genUUID,insertFncls } from "@/util"; // insertFB
import {   apiGetUserInfoByUUID } from "./api"; // apiGetUserInfo
import Cookies from "js-cookie";

export default {
  name: "App",
  components: {
    PopLogin,
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.isLogin,
      showLogin: (state) => state.showLogin,
    }),
  },
  methods: {
    async getUserData() {
      try {
        // const { code, data } = await apiGetUserInfo();
        const {data, code} = await apiGetUserInfoByUUID()
        if (code) {
          return;
        }
        // const {code ,data} =  await apiGetUserInfoByUUID();
        console.log("set user data");
        const user_data= this.$store.state.userData;
        if (user_data &&user_data.id && user_data.atoken && user_data.rtoken){
          // user_data. = 
          data.rtoken = user_data.rtoken;
          data.atoken = user_data.atoken
        }
        this.$store.commit("setUserData", data);
        // this.$store.commit("setUserData", data);
      } catch (error) {
        if (error.status === 401) {
          Cookies.remove("atoken");
          Cookies.remove("rtoken");
          Cookies.remove("uid");
          this.$store.commit("setIsLogin", false);
        }
      }
    },
  },
  async mounted() {
    if (!this.isLogin) {
      console.log("login-not");
      // insertFB();
      let abuid = Cookies.get("abuid");
      console.log("get1", abuid, typeof abuid);
      if (!abuid || abuid == "undefined") {
        abuid = genUUID();
        console.log("get2", abuid);
        Cookies.set("abuid", abuid, { expires: 365 });
        this.$store.commit("setAbuid", abuid);
      }
    } 
    // else {
    // }
      console.log("login1");
      this.getUserData();
      setTimeout(() => {
        insertFncls();
      }, 0);
  },
};
</script>
