import Vue from "vue";
import store from "./store";
import router from "./router";
import App from "./App.vue";
import { Toast, Dialog, Icon } from "vant";
import "vant/lib/index.css";
import("@/assets/style/index.less");

Vue.config.productionTip = false;
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Icon);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");