import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const HomePage = () => import("@/pages/homePage");
const BookShelf = () => import("@/pages/bookshelf");
const BookList = () => import("@/pages/bookList");
const BookRank = () => import("@/pages/bookRank");
const BookDetail = () => import("@/pages/bookDetail");
const BookReader = () => import("@/pages/bookReader");
const ProfilePage = () => import("@/pages/profile");
const paySucess = () => import("@/pages/pay/paySucess.vue");
const payTest = () => import("@/pages/pay/payTest.vue");

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/bookshelf",
    name: "BookShelf",
    component: BookShelf,
  },
  {
    path: "/booklist/:id",
    name: "BookList",
    component: BookList,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/rank",
    name: "BookRank",
    component: BookRank,
  },
  {
    path: "/bookdetail/:id",
    name: "BookDetail",
    component: BookDetail,
  },
  {
    path: "/reader/:id",
    name: "BookReader",
    component: BookReader,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
  },
  {
    path: "/paySucess",
    name: "paySucess",
    component: paySucess,
  },
  {
    path: "/payTest",
    name: "payTest",
    component: payTest,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const route = new VueRouter({
  mode: "history",
  base:"/",
  routes,
});

export default route;
